import {combineReducers} from 'redux';
import ajaxCallsInProgress from './ajaxStatusReducer';
import data from './dataReducer';
import {reducer as toastr} from 'react-redux-toastr'

const rootReducer = combineReducers({
    data,
    ajaxCallsInProgress,
    toastr
});

export default rootReducer;
